import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { connect } from 'react-redux';
//import $ from "jquery";

// reactstrap components
import {Collapse,DropdownMenu,DropdownItem,UncontrolledDropdown,DropdownToggle,ListGroupItem,ListGroup,Media,Navbar,NavItem,NavLink,Nav,Container,Row,Col} from "reactstrap";
import reduxHelper from "./../../helper/index.js";

import SelectSearch from 'react-select-search'
import NotificationBell from "../../views/pages/modal/NotificationBell";

class AdminNavbar extends React.Component {

  constructor(props) {
    super(props);
    this.helper = new reduxHelper(this.props);
    this.changeCompany = this.changeCompany.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    
    this.state = {
      currentLanguage: window.app.state.user.preference.language
    };

    window.navbar = this;
  }

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  //function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  
  async changeCompany(id) {
    console.log(id);
    await this.helper.preference().savePreference({selectcompany:id});
    this.props.dispatch({type:"SET_DATA_SET_PREFERENCE_COMPANY_SELECT",data:id});
  }

  async handleLanguageChange(value) {
    await this.helper.preference().savePreference({language: value.id});
    await window.app.getLanguageTexts(value.file, value.id);
    window.app.state.user.preference.language = value.id; // Update directly
    this.forceUpdate(); // Force re-render
  }

  render() {

    //Parar verificar se deve mostrar o select de unidade
    var mostrar = false;

    if(this.props.company.length >= 2){ mostrar = true; }

    if(this.props.company.length === 1){
 
      if(this.props.company[0].items.length > 1){ mostrar = true; }

      //Caso seja somente uma
      if(this.props.company[0].items.length === 1){
 
        if(this.props.user.preference.selectcompany === this.props.company[0].items[0].value){}else{

          console.log('NÃO É IGUAL'); 
          this.helper.preference().savePreference({selectcompany:this.props.company[0].items[0].value});
          this.props.dispatch({type:"SET_DATA_SET_PREFERENCE_COMPANY_SELECT",data:this.props.company[0].items[0].value});
          console.log('SETOU');

        }

      }
    
    }

    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}

          style={{color:"#fff",background:"linear-gradient(-45deg,"+window.app.state.confs.headerDegrade1+","+window.app.state.confs.headerDegrade2+")"}} teste="teste"

        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
           
              {

                (mostrar === true) &&
                <React.Fragment>

                  <form className="navbar-search form-inline mr-sm-3 navbar-search-light">
                      <div className="mb-0 form-group" style={{position:'relative'}}> 

                              <SelectSearch options={this.props.company} onChange={ (value) => this.changeCompany(value.value) } value={this.props.user.preference.selectcompany} name="company" placeholder={this.props.translate['escolha_uma_unidade']} />
                              <i className="fas fa-chevron-down" style={{position:'absolute',zIndex:'999',left:'calc(100% - 28px )',top:'8px',fontSize:'14px',color:"#aca7b1"}}></i>
                            
                          <i className="fas fa-chevron-down" style={{position:'absolute',zIndex:'999',left:'calc(100% - 28px )',top:'8px',fontSize:'14px',color:"#aca7b1"}}></i>
                      </div>
                      <button aria-label="Close" className="close" type="button">
                        <span aria-hidden="true">×</span>
                      </button>
                  </form>

                </React.Fragment>

              }

               

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
                <NotificationBell history={this.props.history} />
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a" hidden>
                    <i className="ni ni-bell-55" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        You have <strong className="text-info">13</strong>{" "}
                        notifications.
                      </h6>
                    </div>

                    <ListGroup flush>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>5 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Your posts have been liked a lot.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                    <DropdownItem className="text-center text-info font-weight-bold py-3" href="#pablo" onClick={e => e.preventDefault()} >
                      View all
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" id="bt1a1" color="" tag="a">
                    {window.app.state.languages.find(lang => lang.id === window.app.state.user.preference.language) && (
                      <img 
                        className="animated pulse" 
                        alt={window.app.state.languages.find(lang => lang.id === window.app.state.user.preference.language).name}
                        src={'/flags/' + window.app.state.languages.find(lang => lang.id === window.app.state.user.preference.language).img} 
                        style={{height:'38px', width:'38px', cursor:'pointer'}}
                      />
                    )}
                  </DropdownToggle>
                  <DropdownMenu id="sslanguange" className="dropdown-menu-lg dropdown-menu-dark bg-default" right>
                    <Row className="shortcuts px-4">
                      {window.app.state.languages.map((value, index) => (
                        <Col 
                          style={{cursor:'pointer'}} 
                          key={`lang-${value.id}`} 
                          className="shortcut-item" 
                          onClick={() => this.handleLanguageChange(value)} 
                          xs="4" 
                          tag="a"
                        >
                          <span style={{background:'#0000'}} className="shortcut-media avatar rounded-circle">
                            <img alt={value.name} src={'/flags/' + value.img} />
                          </span>
                          <small style={{marginTop:"0px", color:'#fff'}}>{value.name}</small>
                        </Col>
                      ))}
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar  >
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center" style={{cursor:"pointer"}}>
                      <span className="avatar avatar-sm rounded-circle">
                        <img alt="..." className="photo_user" idc={window.app.state.user.id} src={window.app.state.user.photo} />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">{ window.app.state.user.name }</span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">{this.props.translate['bem_vindo']+'!'}</h6>
                    </DropdownItem>
                    <DropdownItem hidden href="#pablo" onClick={e => e.preventDefault()} >
                      <i className="ni ni-single-02" /> <span>Meu perfil</span>
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={ () => this.props.setState({userPhoto:window.app.state.user.id,openModalChangePhoto:true}) } >
                      <i className="fas fa-camera-retro"></i> <span>{this.props.translate['mudar_foto']}</span>
                    </DropdownItem>

                    <DropdownItem href="#pablo" onClick={ () => this.props.setState({openModalChangePassword:true}) } >
                      <i className="fas fa-key"></i> <span>{this.props.translate['alterar_senha']}</span>
                    </DropdownItem>

                     
                    <DropdownItem hidden href="#pablo" onClick={e => e.preventDefault()} >
                      <i className="ni ni-settings-gear-65" /> <span>{this.props.translate['preferencias']}</span>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem style={{cursor:'pointer'}} onClick={ () => window.app.removeSession() } >
                      <i className="ni ni-user-run" />
                      <span>{this.props.translate['sair']}</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  company: state.company,

}))(AdminNavbar);