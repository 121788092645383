import React from 'react';
import { Table, Button } from 'reactstrap';
import moment from 'moment';

export const ModalDetailedTime = ({
    selectedUserData,
    selectedLessonData,
    sumAllTime,
    onEdit,
    onDelete,
    onAdd
}) => (
    <div className="detailed-time-panel">
        <div className="lesson-header mb-4">
            <h5 className="d-flex align-items-center">
                <i className={`${window.iconsLesson[selectedLessonData?.icon]} mr-2`}></i>
                {selectedLessonData?.name}
            </h5>
            <div className="total-time">
                Tempo Total: <strong>
                    {selectedUserData?.progress[selectedLessonData?.id] ? 
                        sumAllTime(selectedUserData.progress[selectedLessonData.id]) : 
                        '00:00:00'
                    }
                </strong>
            </div>
        </div>

        <Table className="align-items-center" responsive>
            <thead className="thead-light">
                <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Tempo</th>
                    <th scope="col" className="text-right">Ações</th>
                </tr>
            </thead>
            <tbody>
                {selectedUserData?.progress[selectedLessonData?.id]?.map((record, index) => (
                    <tr key={index}>
                        <td>
                            {record.displayDate ? 
                                `${record.displayDate} ${record.displayTime}` : 
                                moment(record.createDate).format('DD/MM/YYYY HH:mm')}
                        </td>
                        <td><span className="font-weight-bold">{record.time}</span></td>
                        <td className="text-right">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-icon btn-2 mr-2"
                                onClick={() => {
                                    onEdit(selectedUserData.id, selectedLessonData.id, record.time, record.createDate);
                                }}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-edit"></i>
                                </span>
                            </Button>
                            <Button
                                color="danger"
                                size="sm"
                                className="btn-icon btn-2"
                                onClick={() => onDelete(selectedUserData.id, selectedLessonData.id, record.time)}
                            >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-trash"></i>
                                </span>
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

        <div className="mt-4 text-center">
            <Button 
                color="primary"
                onClick={() => onAdd(selectedUserData?.id, selectedLessonData?.id)}
            >
                <i className="fas fa-plus mr-2"></i>
                Adicionar Tempo
            </Button>
        </div>
    </div>
);