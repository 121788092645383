import React from "react";
import moment from "moment";
//import $ from 'jquery';
import {Row,Button,CardHeader,UncontrolledTooltip} from "reactstrap";
import { connect } from 'react-redux';
import ModalAgendaSimples from "./../modal/ModalAgendaSimples.jsx";
import ModalLB from "./../modal/ModalLB.jsx";
 

class ListLessons extends React.Component {

	constructor(props){

		super(props);

		this.getUnit = this.getUnit.bind(this);
		this.atividadeAgendada = this.atividadeAgendada.bind(this);
		this.editCalendarLesson = this.editCalendarLesson.bind(this);
		this.timeOutCalendar = this.timeOutCalendar.bind(this);

		this.state = {openModalAgenda:false,erroAgendaLesson:"",dialogAgendaLesson:{lesson:"",date:"",time:""}};
		
		window.listLessons = this;

	}

	editCalendarLesson(lesson,date,time){

		this.setState({openModalAgenda:true,erroAgendaLesson:"",dialogAgendaLesson:{lesson:lesson,date:date,time:time }});

	}

	async getUnit(){

		var unit = this.props.match.params.unit;
		var index = this.props.classroomState.units.findIndex(x => parseInt(x.id) === parseInt(unit));
		unit = this.props.classroomState.units[index];
		await this.setState({unit:unit,index:index});
  
	}

	async componentDidMount(){

		await this.getUnit();
		this.timeOutCalendar();

	}

	//Verifica se a atividade esta agendada
	atividadeAgendada(lesson){
  
		var index = this.props.classroomState.calendar.map(function(x) {return x.lesson; }).indexOf(lesson);
		if(index !== -1){ return this.props.classroomState.calendar[index]; }
		
		return false;

	}

	sumAllTimes(progress){

		var time = '00:00:00';
		time = time.split(':');
 
		for (let index = 0; index < progress.length; index++) {
			
			console.log(progress[index]);
			console.log(progress[index].time);

			var split = progress[index].time.split(':');
			
			if(typeof progress[index].time === 'string'){

				time[0] = (parseInt(time[0])+parseInt(split[0]));
				time[1] = (parseInt(time[1])+parseInt(split[1]));
				time[2] = (parseInt(time[2])+parseInt(split[2]));

			}
			
		}
		
		time[0] = parseInt(time[0]);
		time[1] = parseInt(time[1]);
		time[2] = parseInt(time[2]);

		if(time[0] <= 9){ time[0] = '0'+time[0]; }
		if(time[1] <= 9){ time[1] = '0'+time[1]; }
		if(time[2] <= 9){ time[2] = '0'+time[2]; }

		return time[0]+":"+time[1]+":"+time[2];

	}


	//Verifica lesson
	verifyCalendarLesson(lesson){

		var agora = moment().format('YYYY-MM-DD HH:mm:ss');
		
		var index = this.props.classroomState.calendar.map(function(x) {return x.lesson; }).indexOf(parseInt(lesson));
 
		//Caso não exista no calendario ja retorna true
		if(index === -1){ return true; }
		var calendar = this.props.classroomState.calendar[index];

		return moment(''+calendar.date+' '+calendar.time+'','YYYY-MM-DD HH:mm:ss').isBefore(agora);

	}

	timeOutCalendar(){

		setTimeout(function(){ 
			
			try {

				this.timeOutCalendar();
				this.setState({a:'a'});

			} catch(error) {}
			
		}.bind(this), 10000);

	}

	render(){

 		if(this.state.unit === undefined){ return(<React.Fragment></React.Fragment>); }

		var icon_file = "";

  		//Se tiver icone
		if(this.state.unit.icon_file !== undefined){

			icon_file = sessionStorage.getItem('centerBackend')+this.state.unit.icon_file.url;  

		}


		var lockUnit = false;
		var superUNlockUnit = false;
		for(let index = 0; index < this.props.classroom_option.length; index++) {
			
			if(this.props.classroom_option[index].name === "lock_unit" && parseInt(this.props.classroom_option[index].value) === parseInt(this.state.unit.id)){ 
				
				lockUnit = true; 
			
			}
			
			//Verificar se te um SUPÉRUNLOCK
			if(this.props.classroom_option[index].name === "superunlock_unit" && parseInt(this.props.classroom_option[index].value) === parseInt(this.state.unit.id)){ 
				
				lockUnit = false;
				superUNlockUnit = true;
			
			}

		}
		
		 
		window.accessNextLesson = true;

		return(<React.Fragment>
			 
				<div className="mx-auto col-md-11 col-sm-12 col-xs-12" style={{padding:'0px'}}>
					<Row className="justify-content-center">
	 
						<React.Fragment>
							<center>
								<div style={{display:'flex',textAlign:'center',alignItems:'center',marginTop:"20px"}}>
									<img style={{maxHeight:'110px'}} alt="..." src={icon_file} />
									<div>
										<h2 style={{marginLeft:'20px',color:"#000"}}>{this.state.unit.name}</h2>
										<Button onClick={ () => this.props.history.push('/myclassroom/'+this.props.match.params.classrom) } color="primary" size="sm" type="button"><i className="fas fa-undo"></i> {this.props.translate['voltar']}</Button>
										{

											(this.props.permissions_force.indexOf(35) !== -1) &&
											<React.Fragment>
												{

													(lockUnit === true) &&
													<Button onClick={ () => this.props.deleteOption(this.state.unit.id,"lock_unit") } color="danger" size="sm" type="button"><i className="fas fa-lock"></i> Desbloquear unit</Button>
												}
												{

													(lockUnit === false) &&
													<Button onClick={ () => this.props.insertOption(this.state.unit.id,"lock_unit") } color="secondary" size="sm" type="button"><i className="fas fa-lock-open"></i> Bloquear unit</Button>

												}
											</React.Fragment>


										}
									</div>
								</div>
							</center>
							<div className="col-md-12" style={{marginBottom:'30px'}}></div>
						</React.Fragment>
					</Row>
					{

						(lockUnit === true) &&
						<React.Fragment>

							{

								(this.props.permissions_force.indexOf(35) === -1) &&
								<div className="alert alert-warning fade show animated " role="alert">
									<strong>Atenção!</strong> O professor <strong>bloqueou</strong> todas as atividades dessa unit, você precisa aguardar até que ele libere.
								</div>

							}

							{

								(this.props.permissions_force.indexOf(35) !== -1) &&
								<div className="alert alert-warning fade show animated " role="alert">
									<strong>Atenção!</strong> Essa <strong>UNIT</strong> foi bloqueada, os alunos não terão acesso a nenhum atividade dessa Unit!
								</div>

							}

						</React.Fragment>
						 
					}
					
					<CardHeader style={{backgroundColor:'#fff0',padding:'0px',marginBottom:'35px'}}>
									 
										{ 	(this.state.unit.lessons.length !== 0) &&

											<div className="table-responsive" style={{boxShadow:'0px 2px 9px -7px #000'}}>
												<div  >
													<table className="align-items-center table-flush table" style={{backgroundColor:'#fff',borderRadius:'5px'}}>

														<thead className="thead-light">
															<tr>
																<th scope="col" style={{borderColor:'#e9ecef00',borderRadius:'5px'}}>{this.props.translate['nome']}</th>
																<th scope="col" style={{borderColor:'#e9ecef00'}}><center>{this.props.translate['tempo_na_atividade']}</center></th>
																<th scope="col" style={{borderColor:'#e9ecef00'}}>{this.props.translate['ultimo_acesso']}</th>
																<th scope="col" style={{borderColor:'#e9ecef00'}}>Agendamento</th>
																<th scope="col" style={{borderColor:'#e9ecef00',borderRadius:'5px'}}></th>
															</tr>
														</thead>

														<tbody style={{backgroundColor:'#fff'}}>
													 	{ 
																this.state.unit.lessons.map((value,index) => {
																	
																	var agendamento = this.atividadeAgendada(value.id);
																	var accessLesson = false;

																	//verifica se estou acessando a primeira unit
	  																if(index === 0){

	  																	//se for a primeira unit, e se for a primeira lesson... ele liberar
	  																	if(index === 0){ accessLesson = true ; }

	  																}

	  																//Verifica se esse é a segunda lesson
	  																if(index !== 0){

	  																	//verifica se ja fiz algo na anterior
	  																	if(this.state.unit.lessons[(index - 1)].progress.length !== 0){ 

	  																		accessLesson = true; 

	  																	}else{ 

	  																		accessLesson = false; 

	  																	}

	  																}	
	  																

	  																//Caso tenha a permissão libera...
																	if(this.props.permissions_force.indexOf(20) !== -1){ accessLesson = true; }

																	
																	//Verifica lesson bloqueada
																	var lock = false;
																	for(let index = 0; index < this.props.classroom_option.length; index++) {
																	
																		if(this.props.classroom_option[index].name === "lock_lesson" && parseInt(this.props.classroom_option[index].value) === parseInt(value.id)

																		){ 
																			
																			lock = true;  
																			if(this.props.permissions_force.indexOf(20) !== -1){ 
																				accessLesson = true;  
																			}else{
																				accessLesson = false;
																			}

																		}
																		
																	}

																	//Verifica se a unit foi bloqueada
																	if(lockUnit === true){

																		if(this.props.permissions_force.indexOf(20) !== -1){ 
																			accessLesson = true;  
																		}else{
																			accessLesson = false;
																		}

																	}


																	if(this.verifyCalendarLesson(value.id) === false){

																		//verifica se ja esta liberada
																		if(accessLesson === true){

																			if(this.props.permissions_force.indexOf(20) !== -1){ 
																				accessLesson = true;  
																			}else{
																				accessLesson = false;
																			}

																		}
																		
																	}

																	//Verificação final
																	if(accessLesson === false){ window.accessNextLesson = false; }

																	if(window.accessNextLesson === false){

																		if(this.props.permissions_force.indexOf(20) !== -1){ 
																			accessLesson = true;  
																		}else{
																			accessLesson = false;
																		}
																		
																	}


																	//Caso tenha uma super UNLOCK na unit
																	if(superUNlockUnit === true){

																		accessLesson = true;

																	}



																	//find lb
																	var cl = window.admin.state.classrooms.find(x => x.id === parseInt(this.props.match.params.classrom));
																	console.log('cl');
																	console.log(cl);
																	console.log('cl');

																	if(cl !== undefined){

																		console.log('cl.unloks');
																		console.log(cl.unloks);

																		console.log('this.props.user.id: '+this.props.user.id);
																		console.log('value.id: '+value.id);

																		var clbl = cl.unloks.find(x => (x.user == this.props.user.id && x.lesson === value.id));
																		console.log('clbl');
																		console.log(clbl);
																		if(clbl !== undefined){
																			accessLesson = true;
																		}


																	}

																	return(
																		<tr className="hvr-fade" style={{display:'table-row'}} idc={value.id} key={value.id}>
																			<th scope="row" style={{cursor:'pointer'}} onClick={ () => { if(accessLesson === true){ this.props.history.push('/myclassroom/'+this.props.match.params.classrom+'/'+this.props.match.params.unit+'/'+value.id); } }}>
																				<span hidden className="timeline-step badge-info" style={{left:'80px',position:'absolute'}}>
																					<i  className={window.iconsLesson[value.icon]} />   
																				</span> 
																				
																				<font><i style={{marginRight:'10px',fontSize:'15px'}} className={window.iconsLesson[value.icon]} /></font>
																				<font>{value.name}</font>
																			</th>
																			<th scope="row">

																				<center>{ this.sumAllTimes(value.progress) }</center>

																			</th>
																			<th scope="row">

																				{

																					(value.progress.length !== 0) &&
																					<React.Fragment>
																						{ moment(value.progress[(value.progress.length-1)].createDate).format('HH:mm:ss') }
																						<font> em </font>
																						{ moment(value.progress[(value.progress.length-1)].createDate).format('DD/MM/YYYY') }
																					</React.Fragment>

																				}

																			</th>
																			<th scope="row">
																				
																				{
																					
																					(this.props.permissions_force.indexOf(27) !== -1) &&
																					<React.Fragment>
																					{

																						(agendamento === false) &&
																						<React.Fragment>
																							<button onClick={ () => this.editCalendarLesson(value.id,'','') } type="button" className="animated btn btn-secondary btn-sm"><i className="far fa-calendar-alt"></i> Agendar</button>
																						</React.Fragment>

																					}{

																						(agendamento !== false) &&
																						<React.Fragment>
																							
																							<div onClick={ () => this.editCalendarLesson(value.id,moment(agendamento.date,'YYYY-MM-DD').format('DD/MM/YYYY'),agendamento.time) } style={{cursor:'pointer'}}>

																								{moment(agendamento.date,'YYYY-MM-DD').format('DD/MM/YYYY')} ás {moment(agendamento.time,'HH:mm:ss').format('HH:mm')}
																								<i style={{marginLeft:'5px'}} className="fas fa-edit"></i>
																							
																							</div>	

																						</React.Fragment>
																						
																					}
																					</React.Fragment>
																					
																				}

																				{

																					(this.props.permissions_force.indexOf(27) === -1 && agendamento !== false) &&
																					<div  style={{cursor:'pointer'}}>

																						{moment(agendamento.date,'YYYY-MM-DD').format('DD/MM/YYYY')} ás {moment(agendamento.time,'HH:mm:ss').format('HH:mm')}
																							
																					</div>	
																				}
																					
																			</th>
																			<th scope="row">
																				<center>
																				{
																					(this.props.permissions_force.indexOf(35) !== -1) &&
																					<React.Fragment>
																						<React.Fragment>

																							<Button className="animated " onClick={ () => { 
																								
																								this.setState({
																									openModalLB:true,
																									lbname:value.name,
																									lbid:value.id

																								}); 
																						
																						}} id={'aatd_'+value.id} color="secondary" size="sm" type="button"><i className="fas fa-lock-open"></i> LB individual</Button>
																							<UncontrolledTooltip placement="left" delay={0} target={'aatd_'+value.id+''}>Liberação individual</UncontrolledTooltip>

																						</React.Fragment>

																						{

																							(lock === true) &&
																							<React.Fragment>

																								<Button className="animated " onClick={ () => this.props.deleteOption(value.id,"lock_lesson") } id={'aat_'+value.id} color="danger" size="sm" type="button"><i className="fas fa-lock"></i></Button>
																								<UncontrolledTooltip placement="left" delay={0} target={'aat_'+value.id+''}>Desbloquar atividade</UncontrolledTooltip>

																							</React.Fragment>

																						}
																						{

																							(lock === false) &&
																							<React.Fragment>

																								<Button className="animated " onClick={ () => this.props.insertOption(value.id,"lock_lesson") } id={'aat_'+value.id} color="secondary" size="sm" type="button"><i className="fas fa-lock-open"></i></Button>
																								<UncontrolledTooltip placement="left" delay={0} target={'aat_'+value.id+''}>Bloquear atividade</UncontrolledTooltip>

																							</React.Fragment>

																						}

																					</React.Fragment>

																				}
																				
																				
																				{

																					(accessLesson === true) &&
																					<React.Fragment>
																						<Button className="animated " onClick={ () => this.props.history.push('/myclassroom/'+this.props.match.params.classrom+'/'+this.props.match.params.unit+'/'+value.id) } color="primary" size="sm" type="button"><i className="fas fa-play"></i> {this.props.translate['iniciar']}</Button>
																					</React.Fragment>

																				}{  

																					(accessLesson === false) && 
																					<React.Fragment>
																						<Button className="animated " color="secondary" size="sm" type="button"><i className="fas fa-lock"></i></Button>
																					</React.Fragment>

																				}
																				</center>
																			</th>
																		</tr>)

																 })
														}
														</tbody>

													</table>
												</div>

												{

													(this.state.unit.lessons.length === 0) &&
													<center style={{backgroundColor:'#fff',padding:'15px'}}>{this.props.translate['nenhuma_atividade_foi_encontrada']}</center>

												}

											</div>
										 	}

											{

												(this.state.unit.lessons.length === 0) &&
												<center style={{backgroundColor:'#fff',padding:'15px'}}>{this.props.translate['nenhuma_lesson_foi_encontrada']}</center>

											}

					</CardHeader>

			</div>

		{/* Modal para editar o calendario de aulas */}
		<ModalAgendaSimples getCalendarClassroom={ () => this.props.getCalendarClassroom() } propsTop={this.props} state={this.state} setState={ (data) => this.setState(data) } />

		{

			(this.state.openModalLB === true) &&
			<ModalLB close={ () => this.setState({openModalLB:false}) } propsTop={this.props} state={this.state} setState={ (data) => this.setState(data) } />

		}
		

			

		</React.Fragment>) 

	}

}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  classroom_option: state.classroom_option,

}))(ListLessons);