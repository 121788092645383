import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import $ from 'jquery';
import {Container,Row,Col,Card,CardHeader} from "reactstrap";
import { Route , Switch} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import ViewLesson from "./ViewLesson.jsx";
import ListUnits from "./course/ListUnits.jsx";
import ListLessons from "./course/ListLessons.jsx";

import ListStudents from "./course/ListStudents.jsx";
import Schedule from "./course/Schedule.jsx";
 
import './../../assets/css/tab_select_classroom.css';
//import ModalManageAdvance from "./modal/ModalManageAdvance.jsx"; 

import { PulseLoader } from "react-spinners";
import ModalPresence from "./modal/ModalPresence.jsx";
import ModalEvaluation from "./modal/ModalEvaluation.jsx";
import ModalTask from "./modal/task/ModalTask.jsx";

class Myclassroom extends React.Component {

	constructor(props){

		super(props);

		//this.state = {loadClass:false,calendar:[],load:false,show404:false};
		this.getDataClassRoom = this.getDataClassRoom.bind(this);
		this.verifyProgressUnit = this.verifyProgressUnit.bind(this);
		this.deleteOption = this.deleteOption.bind(this);
		this.insertOption = this.insertOption.bind(this);
		this.blockAllUnits = this.blockAllUnits.bind(this);
		this.unblockAllUnits = this.unblockAllUnits.bind(this);
		this.getCalendarClassroom = this.getCalendarClassroom.bind(this);
		this.getOptions = this.getOptions.bind(this);
		
		this.state = {
			openModalManageAdvance:false,
			permissions:[],tab:'1',
			show404:true,
			load:false,
			openModalEvaluation:{}, 
			openModalTask: false, 
			openModalTaskUserSend: false,
			taskId: null
		}; 

		this.toggleModal = this.toggleModal.bind(this);
		this.toggleModalUserSend = this.toggleModalUserSend.bind(this)
		this.handleSave = this.handleSave.bind(this);

		this.props.dispatch({type:"CLEAR_CLASSROOM_OPTION",data:{}});
		window.classroom = this;

	}

	toggleModal(taskId = null) {
		this.setState(prevState => ({
		  openModalTask: !prevState.openModalTask,
		  taskId: taskId
		}));
	  }

	  toggleModalUserSend() {
		this.setState(prevState => ({
		  openModalTaskUserSend: !prevState.openModalTaskUserSend
		}));
	  }
	  
	handleSave() {
		this.setState({ openModalTask: false, taskId: null });
	}

	async blockAllUnits(){

		for(let index = 0; index < this.state.units.length; index++){
			
			await this.insertOption(this.state.units[index].id,'lock_unit');
			
		}

	}

	async unblockAllUnits(){

		for(let index = 0; index < this.state.units.length; index++){
		
			await this.deleteOption(this.state.units[index].id,'lock_unit');

		}

	}
	
	//Para desbloquear uma unit
	async deleteOption(value,name){

		var obj = {};
		obj.name = name;
		obj.value = value+"";
		 
		await api.deleteClassRoomOption(this.props.idClass,obj).then((res) => {

			console.log(res.data);
			this.getOptions();
			window.socket.emit('reloadClassRoomOption',{classroom:this.props.idClass});
        
        }).catch(error => {

			console.log(error);
			if(error.response !== undefined){ console.log(error.response); }

        });

	}

	//Para bloquear uma unit
	async insertOption(value,name){

		var obj = {};
		obj.name = name;
		obj.value = value+"";
		 
		await api.insertClassRoomOption(this.props.idClass,obj).then((res) => {

			this.getOptions();
			window.socket.emit('reloadClassRoomOption',{classroom:this.props.idClass});
        
        }).catch(error => {

			console.log(error);
			if(error.response !== undefined){ console.log(error.response); }

        });

	}

	//Pega o calendário da turma
	async getCalendarClassroom(){

		await api.getCalendarClassroom(this.props.idClass).then((res) => {

			this.setState({calendar:res.data});
        
        }).catch(error => {

			console.log(error);
			if(error.response !== undefined){ console.log(error.response); }

        });

	}

	//Para pegar as opções da turma
	async getOptions(){

		await api.getClassRoomOptions(this.props.idClass).then((res) => {

            this.props.dispatch({type:"SET_CLASSROOM_OPTION",data:res.data})

        }).catch(error => {


        });

	}

	//para pegar os dados do curso e da turma
	async getDataClassRoom(){

		await this.getCalendarClassroom();
		await this.getOptions();

		await api.getAcessoClassRoom(this.props.idClass,window.app.state.user.id).then(async (res) => {

			var state = res.data; 
			
			if(window.parseQueryString().redirectLesson !== undefined){

				var lesson = window.parseQueryString().redirectLesson;
				
				for(let index = 0; index < state.units.length; index++) {
					
					for (let index2 = 0; index2 < state.units[index].lessons.length; index2++) {
						
						if(parseInt(state.units[index].lessons[index2].id) === parseInt(lesson)){

							console.log('direcionou');
							console.log('/myclassroom/'+this.props.idClass+'/'+state.units[index].id+'/'+lesson);
							this.props.history.push('/myclassroom/'+this.props.idClass+'/'+state.units[index].id+'/'+lesson);

						}
						
					}
					
				}
			}

			//console.log(state);
			
			
			//Verificar as permissões
			if(state.inclassroom !== undefined){

				if(state.inclassroom.type !== null){

					var perm = window.app.state.permitions.map(function(x) {return x.id; }).indexOf(state.inclassroom.type);
					if(perm !== -1){

						var pms = window.app.state.permitions[perm];
						this.setState({permissions:pms.permissions});

					}

				}
				

			}
			
			await this.setState({load:true,show404:false,inclassroom:state.inclassroom,classroom:state.classroom,course:state.course,units:state.units,loadClass:true});
			//window.app.state.permitions[0].permissions

		}).catch(error => { 

		    console.log(error.data);
		    console.log(error);
			console.log(error.response); 
			
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['ops']}!</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			this.setState({load:true,show404:true});

		    return false; 

		});

		return true;

	}


	verifyProgressUnit(unit,force){

		var index = this.state.units.findIndex(x => parseInt(x.id) === parseInt(unit));
		unit = this.state.units[index];

		//Para não precisar calcular tudo de novo
		//if(unit.progress !== undefined &&){ return unit.progress; }

		//var totalLessons = unit.lessons.length;
		var fez = 0;

		//Para verificar todo o progresso na unit
		for (var i = 0; i < unit.lessons.length; i++) {
				
			if(unit.lessons[i].progress.length > 0){ fez++; }

		}

		//Faz ao calculo do progresso
		var calculo = (fez * 100) / unit.lessons.length;

		//console.log(' ('+fez+' * 100) / '+unit.lessons.length);

		if(isNaN(calculo)){ calculo = 0; }

		//Poe o progresso no statate da unit para não precisar terque calcular de novo
		return calculo;

	}

 	async componentDidMount(){

 		//Para pegar os dados da turma
		await this.getDataClassRoom();
		window.socket.on('reloadClassRoomOption',function(e){
			
			console.log(e);
			console.log('recebou');

			if(parseInt(e.classroom) !== parseInt(this.state.classroom.id)){ return false; }

			this.getOptions();
			this.getCalendarClassroom();

		}.bind(this));

	 }
	  

	//Para pegar os dados da unit direto no state
	componentWillUnmount(){ 

		$("#superBg").fadeOut(0);
		window.socket.off('reloadClassRoomOption');

	}
 
  	render(){

 	
 	 

  	//Caso o curso tenha sido carregado
  	if(this.state.loadClass === true){

  		//Se tiver icone
		if(this.state.course.icon_file !== undefined){

			var icon = sessionStorage.getItem('centerBackend')+this.state.course.icon_file.url;  

		}

		//Se tiver background
		if(this.state.course.background_file !== undefined){

			var background = sessionStorage.getItem('centerBackend')+this.state.course.background_file.url;
			$('#superBg').css('background-image','url('+background+')'); $('#superBg').fadeIn();
			//var styCapa = {background:'url('+background+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'};

		}

  	}


  	//Verifica se estou acessa a lesson
  	var url = window.location.pathname; 
    var temClassRoom = url.search("/myclassroom/");

    var numLesson = url.split('/')
    var showLesson = false;
 	//console.log(numLesson);

    if(parseInt(numLesson[4]) && temClassRoom !== -1){ showLesson = true; }
    //console.log(showLesson);

 	return(


<React.Fragment>
  	
	<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

  	<style>{".navbar-search{ display:none; } .navbar-top{ border-bottom: 0px !important; z-index:1; position: absolute; width: 100%; background: 0 !important; .navbar-top form{ display:none; } "}</style>

  	{ 

  		(this.state.loadClass === true && showLesson === true) &&  
 		<React.Fragment>

 			<link rel="stylesheet" href="/css/lesson.css" />
			<ViewLesson  
			 
			deleteOption={ (a,b) => this.deleteOption(a,b) }
			insertOption={ (a,b) => this.insertOption(a,b) }

			permissions_force={this.state.permissions} history={this.props.history} course={this.state.classroom.course} classroom={numLesson[2]} unit={numLesson[3]} lesson={numLesson[4]} verifyProgressUnit={ (unit,force) => this.verifyProgressUnit(unit,force) } classroomState={this.state} />

 		</React.Fragment>
 		
 		}

 		{ (this.state.loadClass === true && showLesson === false && this.state.show404 === false) &&  

		<React.Fragment>
			<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
			<div className="header pb-5 d-flex align-items-center animated fadeInDown" style={{minHeight:"400px"}}>
	          <span className="mask opacity-8" />
	          <Container className="d-flex align-items-center" fluid>
	            <Row style={{width:'inherit'}}>
	              <Col md="10" xs="12" sm="12" className="mx-auto">
	              <Row>
	                <Col lg="3" style={{padding:'40px',alignItems:'center',display:'flex'}}><img style={{maxHeight:'150px'}} alt=".." className="" src={icon} /></Col>
	                <Col lg="9" style={{padding:'70px 0px'}}>
	                  <h1 className="display-2 text-white">{this.state.course.name}</h1>
	                  <p className="text-white mt-0 mb-3">{this.state.course.description}</p>
	                </Col>
	              </Row>
	              </Col>
	            </Row>
	          </Container>
	        </div>
	        <Container className="mt--6 animated fadeInUp" fluid >
 
          		<Row className="justify-content-center">


				  	<div hidden className="tab_select_classroom">

						{

							[
								{id:'1',name:"Units",icon:"fas fa-chalkboard-teacher"},
								{id:'2',name:"Participantes",icon:"fas fa-users"},
								{id:'3',name:"Aulas",icon:"far fa-list-alt"}
							].map((value,index) => {

								var cls = "pp_1 hvr-bob ";
								if(value.id === this.state.tab){ cls = cls+" ativo "; }

								return(<div><button onClick={ () => this.setState({tab:value.id}) } class={cls}>
											<div className="pp_2"><i class={value.icon}></i></div>
											<div className="pp_3">{value.name}</div>
										</button></div>)	

							})

						}
						 	  

					</div>


            		<Col className="card-wrapper" md="10" xs="12" sm="12">
						{

							(this.state.tab === "2") &&
							<ListStudents classRoom={this.props.idClass} />

						}
						{

							(this.state.tab === "3") &&
							<Schedule classRoom={this.props.idClass} />

						}
            			{

            				(this.state.loadClass === true && this.state.tab === "1") &&
							<Card className="animated fadeIn" style={{marginBottom:'0px'}}>
							  <CardHeader>
							    <Row className="flex-wrap">
							      <Col lg="3" md="6" sm="12" className="mb-3">
							        <h3 className="mb-0">{this.props.translate['turma']}</h3>
							        <h5>{this.state.classroom.name}</h5>
							        <div className="d-flex flex-wrap gap-2">
							          {this.state.permissions.indexOf(35) !== -1 && (
							            <button 
							              onClick={() => this.setState({openModalPresence:true})} 
							              type="button" 
							              className="btn btn-secondary btn-sm"
							            >
							              <i className="fa fa-check-square"></i> Lista de presença
							            </button>
							          )}
							          {this.state.permissions.indexOf(35) !== -1 && (
							            <button 
							              className="btn btn-secondary btn-sm" 
							              onClick={() => this.toggleModal()} 
							            >
							              <span className="btn-inner--icon mr-1">
							                <i className="fas fa-tasks"></i>
							              </span>
							              <span className="btn-inner--text">{this.props.translate['tarefas']}</span>
							            </button>
							          )}
							        </div>
							      </Col>
							      
							      <Col lg="3" md="6" sm="12" className="mb-3">
							        <h3 className="mb-0">{this.props.translate['data_de_abertura']}</h3>
							        <h5>{moment(this.state.classroom.openingDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</h5>
							      </Col>
							      
							      <Col lg="3" md="6" sm="12" className="mb-3">
							        <h3 className="mb-0">{this.props.translate['data_de_fechamento']}</h3>
							        <h5>{moment(this.state.classroom.closingDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</h5>
							      </Col>
							      
							      <Col lg="3" md="6" sm="12">
							        <center>
							          {this.state.permissions.indexOf(35) !== -1 && (
							            <div className="d-flex flex-column gap-2">
							              <div className="d-flex flex-column gap-2">
							                <button 
							                  hidden 
							                  onClick={() => this.setState({openModalManageAdvance:true})} 
							                  type="button" 
							                  className="btn btn-primary btn-sm"
							                >
							                  <i className="fas fa-user-clock"></i> Gerenciar Avanço
							                </button>
							                <button 
							                  onClick={() => this.unblockAllUnits()} 
							                  type="button" 
							                  className="btn btn-secondary btn-sm w-100 mb-2"
							                >
							                  <i className="fas fa-lock-open mr-2"></i> Desbloquear todas as units
							                </button>
							                <button 
							                  onClick={() => this.blockAllUnits()} 
							                  type="button" 
							                  className="btn btn-secondary btn-sm w-100 mb-2"
							                >
							                  <i className="fas fa-lock mr-2"></i> Bloquear todas units
							                </button>
							              </div>
							              <button 
							                onClick={() => window.comment.open({classroom:this.state.classroom.id})} 
							                className="btn btn-secondary btn-sm w-100"
							              >
							                <i className="fas fa-comments mr-2"></i>
							                Comentar
							              </button>
							            </div>
							          )}
							          <div className="d-flex flex-wrap gap-2 justify-content-center">
							            {this.state.permissions.indexOf(35) === -1 && (
							              <>
							                <button
							                  className="btn btn-secondary"
							                  style={{
							                    fontSize: '0.875rem',
							                    padding: '0.75rem 1.5rem'
							                  }}
							                  onClick={this.toggleModalUserSend}
							                >
							                  <i className="fas fa-clipboard-list mr-2"></i>
							                  Minhas Tarefas
							                </button>
							                <button 
							                  className="btn btn-secondary"
							                  style={{
							                    fontSize: '0.875rem',
							                    padding: '0.75rem 1.5rem'
							                  }}
							                  onClick={() => window.comment.open({classroom:this.state.classroom.id})}
							                >
							                  <i className="fas fa-comments mr-2"></i>
							                  Comentar
							                </button>
							              </>
							            )}
							          </div>
							        </center>
							      </Col>
							    </Row>
							  </CardHeader>
							  {	 
										
									<Switch>
										<Route exact={true} path="/myclassroom/:classrom/:unit" component={ (props) => <div className="animated fadeIn"> <ListLessons permissions_force={this.state.permissions} getCalendarClassroom={ () => this.getCalendarClassroom() } deleteOption={(value,name) => this.deleteOption(value,name) }  insertOption={(value,name) => this.insertOption(value,name) } {...props} getOptions={ () => this.getOptions() } history={this.props.history} classroomState={this.state} /></div> } />
										<Route exact={true} path="/myclassroom/:classrom" component={ (props) => <div className="animated fadeIn"><ListUnits permissions_force={this.state.permissions} getCalendarClassroom={ () => this.getCalendarClassroom() } deleteOption={(value,name) => this.deleteOption(value,name) }  insertOption={(value,name) => this.insertOption(value,name) } getOptions={ () => this.getOptions() } verifyProgressUnit={ (unit,force) => this.verifyProgressUnit(unit,force) } {...props} history={this.props.history} classroomState={this.state} /></div> } />
									</Switch>

								}
							</Card>	
							
						}
					</Col>
				</Row>

				<br/><br/><br/><br/>
				
			</Container>
        </React.Fragment>
	}

	<style>{' .sem_perm_turma{ position: fixed; width: calc(100% - '+window.$(".sidenav.navbar-vertical").width()+'px); height: 100%; }  \
			.fmi{ text-shadow: 0px 1px #000; position: absolute; top: calc(50% - 70px); width: 100%; font-size: 27px; text-align: center; color: #fff; }'}</style>
	
	{

		(this.state.show404 === true && this.state.load === true) &&
		<React.Fragment>

			<div style={{position:'fixed',width:'100%',height:'100%',backgroundColor:'#00000087'}}></div>
			<div className="sem_perm_turma animated fadeIn">

				<div className="fmi">
					<i className="fa fa-exclamation fa-3x bounce t2 d2 midix infinite"></i>
					<br/>
					Esta turma não existe, ou você não tem permissão para acessar.
				</div>

			</div>

		</React.Fragment>

	}

	{

		(this.state.load === false) &&
		<React.Fragment> 
			<div style={{position:'fixed',width:'100%',height:'100%',backgroundColor:'#00000087'}}></div>
			<div className="sem_perm_turma animated fadeIn"> 
				<div className="fmi" style={{display:'grid',justifyContent:'center'}}>

					
					<div><PulseLoader size={20} color={"#fff"} loading={true}/></div>
					<div>Carregando curso.</div>
					 
					
				</div>

				 
			</div>
		</React.Fragment>
		 
	}

	{

		(this.state.openModalPresence === true) &&
		<ModalPresence units={this.state.units} classroom={window.classroom.state.classroom.id} close={ () => this.setState({openModalPresence:false}) } />

	}
	{

		(this.state.openModalEvaluation.open === true) &&
		<ModalEvaluation data={this.state.openModalEvaluation} classroom={window.classroom.state.classroom.id} close={ () => this.setState({openModalEvaluation:{}}) } />

	}

	{this.state.openModalTask && (
        <ModalTask
            isOpen={this.state.openModalTask}
            onClose={this.toggleModal}
            taskId={this.state.taskId}
            translate={this.props.translate}
            onSave={this.handleSave}
            classroom={window.classroom.state.classroom.id}
            classroomState={this.state}
            isTeacher={true} 
        />
    )}

	{this.state.openModalTaskUserSend && (
          <ModalTask
            isOpen={this.state.openModalTaskUserSend}
            onClose={this.toggleModalUserSend}
            taskId={this.state.taskId}
            translate={this.props.translate}
            onSave={this.handleSave}
            classroom={window.classroom.state.classroom.id}
            classroomState={this.state}
            isTeacher={false} 
			user={this.props.user}
          />
    )}

</React.Fragment>)
  }
}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
	unit: state.unit,
  
}))(Myclassroom);